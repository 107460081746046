<template>
  <div class="data_container">
    <div class="crumbs data_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
        <el-breadcrumb-item>电量分解对比</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="data_item flex_between">
      <div class="flex_start operation">
        <div class="flex_start">
          <div class="search_item flex_start">
            <span class="search_title">模板名称：</span>
            <el-input v-model="creator" placeholder="请输入" />
          </div>

          <div class="search_item flex_start">
            <el-button type="primary" class="search">查询</el-button>
            <el-button type="info" class="search">重置</el-button>
          </div>
        </div>
      </div>

      <div class="flex_start">
        <el-button class="data_btns flex_center">
          <span>新增电量分解比例</span>
        </el-button>
      </div>
    </div>

    <div class="data_box">
      <el-table
        :data="forecastList.data"
        :header-cell-style="{
          background: '#022C63',
          height: '60px',
          color: '#FFFFFF',
        }"
        :cell-style="{
          background: '#0B183C',
          padding: '15px 0',
          color: '#FFFFFF',
        }"
        class="forecast_table"
      >
        <el-table-column prop="buyer" label="模板名称" align="center" />
        <el-table-column prop="seller" label="创建人" align="center" />
        <el-table-column prop="begin" label="最近修改时间" align="center" />
        <el-table-column prop="detail" label="详情" align="center">
          <el-button type="primary" link>详情</el-button>
        </el-table-column>
      </el-table>

      <!-- <div class="flex_end">
        <el-pagination
          :page-size="20"
          :pager-count="6"
          layout="prev, pager, next"
          :total="1000"
          class="pages"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "decompose",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let creator = ref("");

    let forecastList = reactive({ 
      data: [
        {buyer:'惠州市钰塑精密制造有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-05',end:'120',price:'年分月',power:'1.2'},
        {buyer:'惠州市康惠顺实业有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-05',end:'130',price:'月分日',power:'1.2'},
        {buyer:'惠州市勤兴藤器有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-03',end:'210',price:'月分日',power:'1.2'},
        {buyer:'惠州明德影像科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-04',end:'160',price:'月分日',power:'1.2'},
        {buyer:'平远冠华模具配件有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2021-06',end:'120',price:'日分时',power:'1.2'},
        {buyer:'惠州市永涛塑胶科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-06',end:'250',price:'年分月',power:'1.2'},
        {buyer:'佛山市伟利赢科技有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2022-06',end:'220',price:'年分月',power:'1.2'},
        {buyer:'惠州市恒邦物业管理有限公司',seller:'广东蚂蚁金谷能源有限公司',begin:'2020-06',end:'420',price:'日分时',power:'1.2'},
      ],
    });
    

    // for (let i = 0; i < 13; i++) {
    //   forecastList.data.push({
    //     id: i + 1,
    //     buyer: "企业名字",
    //     seller: "企业名字",
    //     begin: "2022",
    //     end: "2022",
    //   });
    // }

    onMounted(() => {
      nextTick(() => {});
    });
    return {
      forecastList,
      creator,
    };
  },
};
</script>

<style lang="scss" scoped>
.data_box {
  // display: flex;
  // justify-content: space-between;
  margin-bottom: 15px;
}

.data_title {
  margin: 16px 0;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-indent: 1em;

  &::before {
    display: block;
    content: "";
    width: 6px;
    height: 20px;
    background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
    border-radius: 3px 3px 3px 3px;
  }
}

.data_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .data_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}

.forecast_table {
  width: 100%;
  background: #0b183c;

  border-radius: 8px;
  --el-table-border: 2px solid #090a1f;
  --el-table-tr-bg-color: transparent;

  &:deep(.el-scrollbar) {
    padding: 0 10px 15px;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
  }
  &:deep(.el-table__inner-wrapper::before) {
    background: #090a1f;
  }
}

.data_btns {
  background: linear-gradient(180deg, #3090e4 0%, #09d3d5 100%);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  margin-right: 15px;
  border: none;
  &:deep(.icon) {
    padding-right: 10px;
  }
}

.search_item {
  margin-right: 30px;

  .search_title {
    //  width: 60px;
    white-space: nowrap;
  }
}

.pages {
  margin-top: 25px;
  padding: 4px 0;
  background-color: #1f2935;
  --el-pagination-bg-color: #1f2935;
  border-radius: 6px;
  color: #ffffff;
  &:deep(button:disabled) {
    background-color: #1f2935;
  }
  &:deep(.el-pager li.is-active) {
    color: #ffffff;
    background: #0081ff;
    border-radius: 8px;
    margin: 0;
  }
  &:deep(.btn-prev) {
    color: #ffffff;
  }
  &:deep(.btn-next) {
    color: #ffffff;
  }
  &:deep(.btn-quickprev) {
    color: #ffffff;
  }
  &:deep(.btn-quicknext) {
    color: #ffffff;
  }
}
</style>
